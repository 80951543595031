import { observer } from "mobx-react-lite";
import { useStore } from "../store/GlobalStoreContext";
import { FC, useEffect, useState } from "react";
import React from "react";
import { Button, Card, Form, Input } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";

export const Configs: FC<{}> = observer(() => {
  const { configs } = useStore();
  const navigate = useNavigate();
  const [newConfigName, setNewConfigName] = useState("");

  useEffect(() => {
    configs.Load();
  }, [configs]);
  return (
    <>
      <h1>Configs</h1>
      <Card.Group itemsPerRow={3}>
        {Array.from(configs.Configs).map((v) => {
          return (
            <Link to={`/configs/${v.name}`}>
              <Card key={v.name}>
                <Card.Content>
                  <Card.Header>{v.name}</Card.Header>
                  <Card.Description>{v.description}</Card.Description>
                </Card.Content>
              </Card>
            </Link>
          );
        })}
        <Card key="add new">
          <Card.Content>
            <Card.Header>Add new</Card.Header>
            <Form>
              <Form.Field>
                <Input
                  onChange={(e, data) => setNewConfigName(data.value)}
                  error={newConfigName === "" || configs.Has(newConfigName)}
                ></Input>
                <Button
                  disabled={newConfigName === "" || configs.Has(newConfigName)}
                  onClick={() => {
                    configs
                      .SaveAsync({
                        ...configs.Default,
                        ...{ name: newConfigName },
                      })
                      .then(() => navigate(`/configs/${newConfigName}`));
                  }}
                >
                  Add
                </Button>
              </Form.Field>
            </Form>
          </Card.Content>
        </Card>
      </Card.Group>
    </>
  );
});
