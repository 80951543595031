import React, { FC, useEffect, useState } from "react";
import "./App.css";
import { GlobalStore } from "./store/GlobalStore";
import { GlobalStoreContext } from "./store/GlobalStoreContext";

import { Button, List, Loader } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import * as S from "./styled";
import { Configs } from "./configs/Configs";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useSearchParams,
  redirect,
  useParams,
  Link,
} from "react-router-dom";
import { ConfigEditor } from "./configs/ConfigEditor";

const App: FC<{}> = observer(() => {
  const [store] = useState<GlobalStore>(() => new GlobalStore());
  if (!store.isAuth()) {
    return <Button onClick={() => store.login()}>Login</Button>;
  }
  if (store.isLoading) {
    return <Loader>Loading app</Loader>;
  }

  return (
    <>
      <GlobalStoreContext.Provider value={store}>
        <S.Workspace>
          <BrowserRouter>
            <S.Header id="header">
              <Button onClick={() => store.logout()}>
                {" "}
                logout {store.userName}
              </Button>
            </S.Header>
            <S.Navigate id="navigate">
              <List>
                <List.Item>
                  <Link to="/configs"> Configs</Link>
                </List.Item>
              </List>
            </S.Navigate>
            <div id="editor">
              <Routes>
                <Route path="" element={<Configs />}/>
                <Route path="/configs" element={<Configs />}/>
                <Route path="/configs/:configId" element={<ConfigEditor />}/>
              </Routes>
            </div>
          </BrowserRouter>
        </S.Workspace>
        <div className="App"></div>
      </GlobalStoreContext.Provider>
    </>
  );
});

export default App;
