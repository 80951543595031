import { makeAutoObservable, runInAction } from "mobx";

import template from "./defaults/template.json";
import { IConfig } from "../../../../src/types";

export class ConfigStore {
  public Configs: IConfig[];
  public Default: IConfig;
  public Loading: boolean = true;
  constructor(private readonly token: string | undefined) {
    this.Configs = [];
    this.Default = template;
    makeAutoObservable(this);
  }

  public Get(name: string): IConfig | undefined {
    return this.Configs.find((x) => x.name === name);
  }

  public Has(name: string): boolean {
    return this.Get(name) !== undefined;
  }

  public async Load() {
    //TODO :Add API integration
    runInAction(() => {
      this.Loading = true;
    });
    try {
      const configs = (await (
        await fetch("/api/v1/configs", {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
      ).json()) as IConfig[];
      runInAction(() => {
        this.Configs = configs;
      });
    } finally {
      runInAction(() => {
        this.Loading = false;
      });
    }
  }

  public async SaveAsync(config: IConfig) {
    await fetch("/api/v1/configs", {
      method: "POST",
      body: JSON.stringify(config),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });
    runInAction(() => {
      const index = this.Configs.findIndex((x) => x.name === config.name);
      if (index < 0) {
        this.Configs.push(config);
      } else {
        this.Configs[index] = config;
      }
    });
  }
}
